import React from "react";
import { graphql } from "gatsby";

import Layout from "../components/Layout";
import SearchEngineOptimization from "../components/SEO";

const Page = ({ data }) => {
  return (
    <Layout>
      <SearchEngineOptimization
        title="Leave a Review | Point Loma | BayView Assisted Living"
        description="Share your experience with BayView Senior Assisted Living! Leave a review to help others find compassionate, quality senior care in Point Loma."
        // openGraphImage={data.openGraphImage.publicURL}
        //twitterOpenGraphImage={data.twitterOpenGraphImage.publicURL}
      />

      <section className="bg-primary-50 pt-16 pb-20 md:pb-32 md:pt-20">
        <div className="container">
          <div className="mx-auto max-w-3xl">
            <iframe
              src="https://bay-view-reviews-platform.flywheelsites.com/"
              title="Reviews Platform"
              width="100%"
              height="1500px"
              border="0"
            />
          </div>
        </div>
      </section>
    </Layout>
  );
};

export const query = graphql`
  {
    openGraphImage: file(
      relativePath: { eq: "Open Graph/Facebook/Homepage.jpg" }
    ) {
      publicURL
    }
    twitterOpenGraphImage: file(
      relativePath: { eq: "Open Graph/Twitter/Homepage.jpg" }
    ) {
      publicURL
    }
  }
`;

export default Page;
